import * as React from 'react';

//CSS
import '../styles/index.scss';
import '../styles/grids.scss';
import '../styles/particles.scss';
import '../styles/buttons.scss';
import '../styles/elements.scss';
import { particlesComponent } from '../components/particles';
import '../styles/nav.scss';
import '../styles/particles.scss';

import logo from '../images/strategix-logo.svg';

// markup
const ComingSoonPage = () => {
  const props = {
    seoTitle: 'Managed Services',
    seoDescription:
      'Accelerate your business with Strategix Technology Solutions.',
  };

  return (
    // <Layout title={props.seoTitle} description={props.seoDescription}>
    <>
      <head>
        <title>Coming Soon | Strategix Technology Solutions</title>
        <meta
          name="description"
          content="The best kept secret for modernising ICT infrastructure, data,
                applications and security."
        />
        <link rel="icon" type="image/png" href="/images/gatsby-icon.png" />
      </head>
      <main>
        {particlesComponent()}

        {/* Home Hero */}
        <section>
          <div className="hero" id="about">
            <div className="flex-col-c">
              <img
                src={logo}
                style={{
                  width: '100%',
                  objectFit: 'contain',
                  margin: '0',
                }}
                height={150}
                alt="auxo cyber logo"
                className="resp-image"
              />
              <h1 style={{ textAlign: 'center', margin: '0' }}>Coming Soon</h1>
              <p style={{ width: '60%', textAlign: 'center' }}>
                The best kept secret for modernising ICT infrastructure, data,
                applications and security.
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
    // </Layout>
  );
};

export default ComingSoonPage;
