import * as React from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

import '../styles/particles.scss';

export const particlesComponent = () => {
  const particlesInit = async (main: any) => {
    console.log(`main: ${main}`);
    await loadFull(main);
  };

  return (
    <div className="particles-wrapper">
      <Particles
        init={particlesInit}
        id="tsparticles"
        options={{
          zLayers: 1,
          fpslimit: 60,
          interactivity: {
            events: {
              onClick: {
                enable: false,
                mode: 'push',
              },
              onHover: {
                enable: true,
                mode: 'connect',
              },
              resize: true,
            },
          },
          modes: {
            repulse: {
              distance: 200,
              duration: 12,
            },
            push: {
              particles_nb: 4,
            },
            connect: {
              radius: 20,
              links: {
                opacity: 0.8,
              },
            },
          },
          particles: {
            color: {
              value: '#ffffff',
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 3,
                opacity_min: 0.1,
                sync: false,
              },
            },
            links: {
              color: '#ffffff',
              enable: true,
              width: 0.4,
              opacity: 0.6,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: 'none',
              enable: true,
              outModes: {
                default: 'bounce',
              },
              random: false,
              speed: 0.1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 1200,
              },
              value: 50,
            },
            line_linked: {
              enable: true,
              distance: 500,
              opacity: 1,
              width: 10,
              triangles: {
                enable: true,
                color: '#ffffff',
                opacity: 0.8,
              },
            },
            shape: {
              type: 'circle',
              stroke: {
                width: 0,
                color: '#ffffff',
              },
              polygon: {
                nb_sides: 15,
              },
            },

            size: {
              value: {
                min: 1,
                max: 6,
              },
            },
          },
          detectRetina: true,
          preset: 'confetti',
        }}
      />
    </div>
  );
};
